//simple global function to grab store uid from parameter
function grabStoreUid() {
 
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const storeUid = params.get("storeUid") === null ? undefined : params.get("storeUid");

    return storeUid;

};

export default grabStoreUid;
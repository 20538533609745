/* list of question info that may be moved somewhere else eventually */
const questionList = [
    {
        question:"How was your overall experience today",
        selectionType:"radial",
        options:[
            "Excellent", 
            "Good", 
            "Fair", 
            "Poor"
        ],
        choice: "",
        databaseTag: "Satisfaction Q1"
    },
    {
        question: "How was the quality of your food today?",
        selectionType: "radial",
        options: [
            "Excellent", 
            "Good", 
            "Fair", 
            "Poor"
        ],
        choice: "",
        databaseTag: "Satisfaction Q2"
    },
    {
        question: "How long did you wait for your order?",
        selectionType: "radial",
        options: [
            "Less than 5 minutes", 
            "5-10 minutes",
            "More than 10 minutes"
        ],
        choice: "",
        databaseTag: "Satisfaction Q3"
    },
    {
        question: "How friendly was the staff?",
        selectionType: "radial",
        options: [
            "Very Friendly", 
            "Friendly", 
            "Neutral", 
            "Unfriendly"
        ],
        choice: "",
        databaseTag: "Satisfaction Q4"
    },
    {
        question: "Was the restaurant clean?",
        selectionType: "radial",
        options: [
            "Very Clean", 
            "Clean", 
            "Not Very Clean", 
            "Dirty"
        ],
        choice: "",
        databaseTag: "Satisfaction Q5"
    },
    {
        question: "Did you experience or see any broken equipment?",
        selectionType: "radial",
        options: [
            "Yes",
            "No"
        ],
        choice: "",
        databaseTag: "Satisfaction Q6"
    },
    {
        question: "Would you visit us again?",
        selectionType: "radial",
        options: [
            "Yes",
            "No"
        ],
        choice: "",
        databaseTag: "Satisfaction Q7"
    },
];

export default questionList;
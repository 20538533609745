//----REACT ELEMENTS----
import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';

//----EXTERNAL PACKAGES
import axios from "axios";

//----INTERNAL FUNCTIONS
import SubmitScreen from "../../submitScreen/submitScreen";
import SurveyHeader from "../../surveyHeader/surveyHeader";
import ProgressBar from "../../progressBar/progressBar";
import FormElementCreator from "../../formElementCreator/formElementCreator";
import satisfactionSurveyQuestions from "./satisfactionSurveyQuestions";
import PageLoading from "../pageLoading/pageLoading";

// ---Internal Non React Tools ---
import grabStoreUid from "../../globalTools/grabStoreUid";

//----CSS
import "./satisfactionSurvey.css";

function SatisfactionSurvey(props) {

    //used to show loading screen while we wait
    const [loading, setLoading] = useState(true);

    //back button css
    const [backButtonCss, setBackButtonCss] = useState({
        color: "primary",
        easeTimer: 0,
    });

    // next button css
    const [nextButtonCss, setNextButtonCss] = useState({
        color: "primary",
        easeTimer: 0,
    });

    /* keeps track of the current question */
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);

    //keeps track of the background color of the question
    const [questionBaseColor, setQuestionBaseColor] = useState("");

    //tracks if the form was submitted to prevent duplicate form submissions
    const [hasFormBeenSubmit, setHasFormBeenSubmit] = useState(false);

    //grabs store Uid from the url
    const [storeUidState, setStoreUidState] = useState("");

    //props to send to the submit screen image
    const [submitScreenProps, setSubmitScreenProps] = useState({
        waitingForResponse: true,
        message: "Submitting Response",
        buttonInfo: {
            text: "Finish",
            action: "finish"
        }
    });

    /* state of the question info to keep track of what the user has chosen */
    const [questionInfoState, setQuestionInfo] = useState(satisfactionSurveyQuestions);

    //on component load
    useEffect(() => {
        
        //grabs base color of the question bar. We can change bar css and not need to change it here
        getQuestionBaseColor();

        //grab store uid from url
        const storeUid = grabStoreUid();
        
        //returns null if the storeUid value is not found
        setStoreUidState(storeUid);
        
        //confirms process is finished and loads
        setLoading(false)
    

    },[]);

    /* grabs the base color of the question div so use to reset it when its color changes as a warning */
    const getQuestionBaseColor = () => {

        //only does this if loading is complete
        if(loading === false) {

            const currentDivInformation = document.getElementById("testingFormLabelName");
    
            /* grabs the current background color of the div and if there is no then its default is white */
            let currentBackgroundColor = (currentDivInformation.style.backgroundColor === "") ? "#FFFFFF" : currentDivInformation.style.backgroundColor;
    
            setQuestionBaseColor(currentBackgroundColor);

        }

    };

    /* takes the info that is updated in the child component and the question number and updated the current state to have the response */
    const updateQuestionInfo = (choice, questionNumber) => {

        /* makes sure not to break if these are not able to be sent from the child */
        if(choice !== undefined && questionNumber !== undefined) {

            /* makes a deep copy of the state */
            let deepCopyQuestionList = [...questionInfoState];
    
            let currentQuestion = deepCopyQuestionList[questionNumber];

            currentQuestion.choice = choice;
    
            /* updates the copy of the state with the new info */
            deepCopyQuestionList[questionNumber] = currentQuestion;
    
            /* updates the state with the newly selected choice for the form element in question */
            setQuestionInfo(deepCopyQuestionList);

        }

    };

    /* allows for the moving forward and back of questions */
    const questionNavigation = (direction) => {

        const lastQuestionIndex = questionInfoState.length - 1;
        const isLastQuestion = currentQuestionNumber === lastQuestionIndex;

        //checks if the question has been answered
        let isFieldFilled = checkIfFieldIsFilled();

        //if the current question has not been answered yet
        if(isFieldFilled === false && direction === 1) {

            //notifies the user they need to answer the question
            missingQuestionAnswerNotification();
            buttonFeedbackResponse("next", "error")
            
        };

        //current question is answered, next button is clicked and not the last question
        if(isFieldFilled === true && direction === 1 && currentQuestionNumber < lastQuestionIndex) {

            setCurrentQuestionNumber((prevCurrentQuestionNumber) => prevCurrentQuestionNumber + direction);
            buttonFeedbackResponse("next", "success")

        };

        //current question is answered, back button is clicked and not the last question
        if (direction === -1 && currentQuestionNumber > 0) {

            setCurrentQuestionNumber((prevCurrentQuestionNumber) => prevCurrentQuestionNumber + direction);
            buttonFeedbackResponse("back", "success")

        };

        //current question is answered, next button is clicked and its the last question
        if(isFieldFilled === true && direction === 1 && isLastQuestion) {

            submitForm();
            buttonFeedbackResponse("next", "success")

        };

    };

    /* determines if a question has been answers in regards to if the user can proceed to the next question */
    const checkIfFieldIsFilled = () => {

        let currentFieldValue = questionInfoState[currentQuestionNumber].choice;

        let isFieldFilled = false;

        if (currentFieldValue !== "") isFieldFilled = true;

        return isFieldFilled;

    };

    //if the current question has not been answered then flash the question red to indicate to the user
    const missingQuestionAnswerNotification = () => {

        const currentDivInformation = document.getElementById("testingFormLabelName");

        /* grabs the current background color of the div and if there is no then its default is white */
        let currentBackgroundColor = questionBaseColor;

        currentDivInformation.style.transitionDuration = ".1s";
        currentDivInformation.style.backgroundColor = "rgb(255 92 92)";

        setTimeout(() => {

            currentDivInformation.style.transitionDuration = "1s";
            currentDivInformation.style.backgroundColor = currentBackgroundColor;

        }, 500)

    };

    //submits the form to the server if all questions have answers
    const submitForm = async () => {

        if(hasFormBeenSubmit === false) {

            setHasFormBeenSubmit(true);
    
            const apiPostBody = {};
    
            /* goes through each array of the index ot grab the question info and the database tag */
            questionInfoState.forEach((questionInfoObject) => {
    
                const databaseQuestionTag = questionInfoObject.databaseTag;
                const questionAnswerChoice = questionInfoObject.choice;
    
                apiPostBody[databaseQuestionTag] = questionAnswerChoice;
    
            });
        
            if (storeUidState !== undefined) {

                let submitScreenPropsCopy = {...submitScreenProps};

                submitScreenPropsCopy.waitingForResponse = true;
                submitScreenPropsCopy.message = "Submitting Response to server."
                submitScreenPropsCopy.buttonInfo = {
                    text: "Waiting for response",
                    action: ""
                }

                setSubmitScreenProps(submitScreenPropsCopy)
                displaySubmitScreen();

                //does not need full url since its calling itself
                const surveyAppUrl = `/api/satisfactionSurvey/satisfaction?storeUid=${storeUidState}`;
        
                let axiosGetTableHeaderInfoConfig = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: surveyAppUrl,
                    data: apiPostBody
                };
        
                try {

                    let response = await axios.request(axiosGetTableHeaderInfoConfig);

                    await sleep(2);

                    let successfulFormSubmissionPropsCopy = {...submitScreenProps};

                    successfulFormSubmissionPropsCopy.waitingForResponse = false;
                    successfulFormSubmissionPropsCopy.message = "Response Submitted"
                    successfulFormSubmissionPropsCopy.buttonInfo = {
                        text: "Finish",
                        action: "finish"
                    };

                    setSubmitScreenProps(successfulFormSubmissionPropsCopy)
        
                } catch (error) {
                    
                    if(error.response === undefined){ //usually means an issue with the call itself not the data in the call
                        
                        submitScreenPropsCopy.waitingForResponse = false;
                        submitScreenPropsCopy.message = "An unknown error occurred. Please try again at another time."
                        submitScreenPropsCopy.buttonInfo = {
                            text: "Back",
                            action: "back"
                        }
    
                        setSubmitScreenProps(submitScreenPropsCopy);

                    }else {

                        const importMessage = error.response.data.message;
    
                        let submitScreenPropsCopy = {...submitScreenProps};
    
                        submitScreenPropsCopy.waitingForResponse = false;
                        submitScreenPropsCopy.message = "An unknown error occurred. Please try again at another time."
                        submitScreenPropsCopy.buttonInfo = {
                            text: "Back",
                            action: "back"
                        }
    
                        setSubmitScreenProps(submitScreenPropsCopy);

                    }

                    //console.log(importMessage)
        
                }
    
            }else{

                let submitScreenPropsCopy = {...submitScreenProps};

                submitScreenPropsCopy.waitingForResponse = false;
                submitScreenPropsCopy.message = "Invalid url, please scan QR code and try again."
                submitScreenPropsCopy.buttonInfo = {
                    text: "Back",
                    action: "back"
                }

                setSubmitScreenProps(submitScreenPropsCopy)
                displaySubmitScreen();

            }

        }
    };
    
    /* simple function to display submit screen */
    const displaySubmitScreen = () => {

        let submitScreenContainerInfo = document.getElementById("submitScreenContainer");
        submitScreenContainerInfo.style.display = "flex";

    };

    //flashes the color of the button red or green depending on if its an error or success then fades to original
    const buttonFeedbackResponse = (direction, status) => {

        if(direction === "back") {

            //instantly changes color to the status color
            const currentButtonState = {...backButtonCss};
            currentButtonState.easeTimer = 0;
            currentButtonState.color = status;
            setBackButtonCss(currentButtonState);

            setTimeout(()=> {

                //changes the color back to primary over 1 second
                const updatedButtonState = {...backButtonCss};
                updatedButtonState.easeTimer = 1;
                updatedButtonState.color = "primary";
                setBackButtonCss(updatedButtonState);

            },500)


        }else if(direction === "next") {

            //instantly changes color to the status color
            const currentButtonState = {...nextButtonCss};
            currentButtonState.easeTimer = 0;
            currentButtonState.color = status;
            setNextButtonCss(currentButtonState);

            setTimeout(()=> {

                //changes the color back to primary over 1 second
                const updatedButtonState = {...nextButtonCss};
                updatedButtonState.easeTimer = 1;
                updatedButtonState.color = "primary";
                setNextButtonCss(updatedButtonState);

            },500)

        }

    };

    //sleep function for development
    const sleep = (seconds) => {
        return new Promise((resolve) => setTimeout(resolve, seconds*1000))
    };

    if(loading) {

        return <PageLoading />

    }

    return(
        
        <div className="satisfactionSurveyContainer">

            <SubmitScreen 
                surveyName={"Satisfaction Survey"}
                surveyMessage={"Thank you for completing the survey! Have a nice day."}
                displayInfo={submitScreenProps}
            />

            <SurveyHeader
                showImage={true} //is image show
                defaultImage={false} //use default image or the one for the client
                headerText={"Satisfaction Survey"} //text for the header
                storeUid={storeUidState} //store uid to grab icon with
            />

            <ProgressBar 
                currentQuestionNumber={currentQuestionNumber}
                numberOfQuestions={questionInfoState.length}
            />

            <div className="formContainer">
                
                <FormElementCreator
                    //creates the current form question based on the info provided such as the question, the choices and more
                    question={questionInfoState[currentQuestionNumber].question}
                    selectionType={questionInfoState[currentQuestionNumber].selectionType}
                    options={questionInfoState[currentQuestionNumber].options}
                    choice={questionInfoState[currentQuestionNumber].choice}
                    questionNumber={currentQuestionNumber}
                    updateQuestionInfo={updateQuestionInfo}
                />

                <div className="navigationBarContainer">

                    <Button
                        //back button that gets disabled when on the first question and flashes error or success colors
                        className="surveyButtons"
                        variant="contained"
                        color={backButtonCss.color}
                        value={"back"}
                        onClick={() => questionNavigation(-1)}
                        disabled={currentQuestionNumber === 0 ? true : false}
                        sx={{
                            bgcolor: backButtonCss.color,
                            color: "white",
                            transition: `background-color ${backButtonCss.easeTimer}s ease`
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        //next button that changes to submit when on the last question and flashes error or success colors
                        className="surveyButtons"
                        variant="contained"
                        color={nextButtonCss.color}
                        value={"next"}
                        onClick={() => questionNavigation(1)}
                        sx={{
                            bgcolor: nextButtonCss.color,
                            color: "white",
                            transition: `background-color ${nextButtonCss.easeTimer}s ease`
                        }}
                    >
                        {currentQuestionNumber !== questionInfoState.length -1 ? "Next" : "Submit"}
                    </Button>   

                </div>

            </div>   
                
        </div>
    )

};

export default SatisfactionSurvey;
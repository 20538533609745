/* object to keep track of the active/possible routes. Add new routes here and reference this in App.js in react */
/* this both provides info to react and the server in order to identify which paths are currently available */
const routeInformationObject = {
    home: "/",                
    satisfaction: "/quickbaseSatisfaction",
    serviceTicketClockIn: "/serviceTicketClockIn",
    formSubmission: "/formSubmission",
    globalRateLimit: "/globalRequestLimit",
    individualRateLimit: "/individualRequestLimit"
}

//returns an array of lowercase route names to be used 
const getRouteList = () => {

    const routeListArray =  Object.values(routeInformationObject);

    const lowerCaseList = [];

    routeListArray.forEach((currentRoute) => {

        lowerCaseList.push(currentRoute.toLocaleLowerCase());

    });

    return lowerCaseList;
}

module.exports = {routeInformationObject, getRouteList};
import React from "react";

// --- Material UI Elements
import CircularProgress from "@mui/material/CircularProgress";

//Simple component that loads a loading screen while waiting for information
function PageLoading() {

    return (

        <div className="loadingPageContainer">

            <CircularProgress />

        </div>
    )

};

export default PageLoading;
//--- External Packages ---
import React, {useState, useEffect} from "react";

//---MUI Elements---
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/system";
import { keyframes } from "@mui/material";

//---Internal React Components---
import FormElementCreator from "../../formElementCreator/formElementCreator";

//---Internal reusable code---

//---Internal Non React Tools ---

//--- Development tools ---
import sleepTimer from "../../_developmentTools/devSleepTimer";

//--- Styles ---
import "./renderServiceTicket.css";
const progressWheelSize = "20px";
const progressWheelStyle = {
    width: progressWheelSize, 
    height: progressWheelSize, 
    color: "black"
};

/**
 * Description: Renders a single service ticket in the service ticket app
 * @param {object} props.currentTicketInfo - object storing information about the current ticket
 * @param {object} props.loadingButtonStatus - stores info about the status of the ticket to be used for the buttons
 * @param {function} props.serviceTicketConfirmationPopup - function passed down to be used in the button to update the database
 * @returns 
 */
function RenderServiceTickets(props) {

    //----- STATE -----
    const [isImageLoaded, setIsImageLoaded] = useState(false); //tracks the image loading status
    const [blinkColor, setBlinkColor] = useState("#ffffff00") //used to change the color of the text box for the disposition
    const [disposition, setDisposition] = useState(""); //stores the text in the text box for the disposition

    //----- PROPS ----- Used to more easily use common props throughout the app
    const status = props.currentTicketInfo.status;
    const loadingButtonStatus = props.loadingButtonStatus;
    
    //updates the disposition when the form element create for the text box is edited
    const textBoxChange = (event) => {

        const updatedField = event.target.value;

        setDisposition(updatedField)

    };

    //api call to make update service ticket in database depending on button pressed
    const updateServiceTicket = (statusType) => {

        //just in case
        if(statusType !== "start" && statusType !== "complete") {

            console.log("Unexpected status type was found")
            return

        }
        
        //if the status is complete and the disposition is empty
        /* if(statusType === "complete" && disposition.length <= 0) {

            console.log("disposition needed")

            setBlinkColor("#ff8e8e")

            setTimeout(() => setBlinkColor("#ffffff00"), 150); // Stop the animation after 1 second

            return
        
        } */

        //creates an object to pass to the api call used to update the database
        const serviceTicketStatusChangeArguments = {
            
            //used for api call to update data
            type: statusType,
            key: props.currentTicketInfo.key,
            disposition: disposition,

            //used to display information in confirmation popup
            ticketDescription: props.currentTicketInfo.ticketDescription,
            ticketNumber: props.currentTicketInfo.ticketNumber,
            storeNumber: props.currentTicketInfo.storeNumber,
            pictures: props.currentTicketInfo.pictures
        };

        //calls the function passed down to update the database
        props.serviceTicketConfirmationPopup(serviceTicketStatusChangeArguments)

    };

    //will run on component mount or when isImageLoaded changes
    useEffect(() => {
        
        //only needs to set timeout if the image hasnt loaded yet
        if(!isImageLoaded) {
            
            const timerSeconds = 3;
            
            //after timer seconds, set isImageLoaded to true. Likely because of a broken image link
            const timer = setTimeout(() => {

                setIsImageLoaded(true)
    
            },timerSeconds*1000);
    
            //clears the timeout when the component unmounts
            return () => clearTimeout(timer);

        };

    //passes isImageLoaded as dependency, so when it changes, the timeout is cleared and it runs again with updated isImageLoaded
    },[isImageLoaded]);

    return(

        //renders the service ticket
        <div 
            className="individualServiceTicketContainer" 
            key={props.currentKey}
            style= {{ display: isImageLoaded ? "flex" : "none"}}
        >
 
            <div 
                className="renderedServiceTicketContainer" 
                key={`key_${props.currentTicketInfo.key}-location_${props.currentTicketInfo.storeNumber}`}
            >

                {/* renders the description */}
                <div className="renderedServiceTicket_DescriptionContainer">

                    <p><b>Description:</b> {props.currentTicketInfo.ticketDescription}</p> 

                </div>

                {/* container for image */}
                <div className="renderedServiceTicket_ImageContainer">

                    {/* when image has completed loading, run handleImageLoad function which will change state to true */}
                    <div 
                        className="serviceTicketImageLoadingContainer"
                        style={{display: isImageLoaded ? "none" : "block"}}
                    >

                        <CircularProgress />

                    </div>

                    <img
                        src={props.currentTicketInfo.pictures.imageLinksList[0]}
                        onLoad={() => setIsImageLoaded(true)} //on image load, set isImageLoaded to true
                        style={{display: isImageLoaded ? "block" : "none"}}
                        alt="image of broken equipment"
                        className="serviceTicketImage imageOne"
                    />
                    

                </div>

                {/* container for extra info */}
                <div className="renderedServiceTicket_ExtraInfoContainer">

                    <div className="renderedServiceTicket_ExtraInfoContainer_Age">

                        <p><b>Age:</b> {props.currentTicketInfo.age} days</p> 

                    </div>

                    <div className="renderedServiceTicket_ExtraInfoContainer_Status">

                        <p><b>Status:</b> {props.currentTicketInfo.status}</p> 

                    </div>
                
                </div>

                {/* loads disposition field only if the ticket is in progress */}
                {/* <div
                    className="dispositionFieldContainer" 
                    style={{
                        display: status === "In Progress" ? "flex":"none"}}
                >

                    <TextField
                        label="Disposition"
                        className="textFieldTest"
                        InputLabelProps={{}}
                        id="serviceTicketDisposition"
                        variant="outlined" //outlined, filled, standard
                        onChange={(event) => textBoxChange(event)}
                        value={disposition}
                        style={{
                            width: "100%",
                            backgroundColor: blinkColor
                            }}
                    />

                </div> */}

                {/* renders the buttons */}
                <div className="renderServiceTicket_ButtonContainer">

                    <div className="renderServiceTicketButton">

                        <Button
                            variant="contained"
                            sx={{height: "100%"}}
                            disabled={status === "In Progress" ? true:false} //disables it depending on in progress is true or false
                            onClick={() => updateServiceTicket("start")}
                        >
                            {/* adds loading bar to button when pressed */}
                            {loadingButtonStatus.start === false ? "Start" : <CircularProgress style={progressWheelStyle}/>}

                        </Button>

                    </div>

                    <div className="renderServiceTicketButton">

                        <Button
                            variant="contained"
                            sx={{height: "100%"}}
                            disabled={status !== "In Progress" ? true:false} //disables it depending on in progress is true or false
                            onClick={() => updateServiceTicket("complete")}             
                        >
                            {/* adds loading bar to button when pressed */}
                            {loadingButtonStatus.complete === false ? "Complete" : <CircularProgress style={progressWheelStyle}/>}

                        </Button>

                    </div>

                </div>

            </div>

        </div>
    )

};

export default RenderServiceTickets;
//--- External Packages ---
import React, {useState, useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";

/* ---MUI Elements--- */

/* ---Internal React Components--- */
import SubmitLoadingButton from "../../uiElements/submitLoadingButton";
import RenderServiceTickets from "./renderServiceTicket";

/* ---Internal reusable code--- */
import SurveyHeader from "../../surveyHeader/surveyHeader";
import PageLoading from "../pageLoading/pageLoading";
import TicketConfirmationPopup from "./ticketConfirmationPopup";

// ---Internal Non React Tools ---
import grabStoreUid from "../../globalTools/grabStoreUid";

//--- Development tools ---
import sleepFunction from "../../globalTools/sleepFunction";

//--- Styles ---
import "./serviceTicketApp.css"

/**
 * Description: Renders the service ticket app
 * @returns 
 */
function ServiceTicketApp(props) {

    //used to show loading screen while we wait
    const [loading, setLoading] = useState(true);

    //used to identify if the ticket confirmation popup is displayed
    const [ticketConfirmationPopupDisplay, setTicketConfirmationPopupDisplay] = useState("none");

    //stores selected ticket info
    const [selectedTicketInfo, setSelectedTicketInfo] = useState({});

    //used to store the uid of the store
    const [storeUidState, setStoreUidState] = useState("");

    //list of service tickets that match the search criteria
    const [serviceTicketApiList, setServiceTicketApiList] = useState([]);

    //message info used to display to the user
    const [serviceTicketMessageInfo, setServiceTicketMessageInfo] = useState({ message: "Search for store tickets", color: "black"});

    //locks the button to prevent multiple clicks
    const [submitButtonLock, setSubmitButtonLock] = useState(false);

    //stores the api call status
    const [apiCallStatus, setApiCallStatus] = useState("complete");

    //stores the loading button status to disable buttons on individual tickets
    const [loadingButtonStatus, setLoadingButtonStatus] = useState({});

    //stores the raw search params
    const [rawSearchParams] = useSearchParams();

    //api call to grab service tickets based on search criteria
    const searchForServiceTicket = async () => {

        setServiceTicketApiList([]);

        //if the button is not locked we can run the api call and lock it until it finishes
        if(submitButtonLock !== true) {

            //locks button and sets api call status to loading
            setSubmitButtonLock(true);
            setApiCallStatus("loading")

            const paramsList = Object.entries([...rawSearchParams]);

            const paramObject = {};

            /* iterates through the very weird response to the params and creates an object of the params (key:value) */
            for(const currentParam of paramsList) {

                const currentParamInfo = currentParam[1];

                paramObject[currentParamInfo[0]] = currentParamInfo[1]
            };

            //if store uid is not found, we cant continue
            if(paramObject.storeUid === undefined) {
                
                setSubmitButtonLock(false);
                setApiCallStatus("complete");
                renderServiceTicketApiCallMessage("Invalid store URL");
                return

            }

            //does not need full url since its calling itself
            const getServiceTicketListEndpoint = `/api/serviceTicket/getServiceTicketList?storeUid=${paramObject.storeUid}`;
                    
            //arguments for axios request call
            const axiosGetServiceTicketListSettings = {
                method: "get",
                maxBodyLength: Infinity,
                url: getServiceTicketListEndpoint,
            };

            try {

                //response for the api call
                const serviceTicketListResponse = await axios.request(axiosGetServiceTicketListSettings);

                //grabs message and list from response
                const serviceTicketMessage = serviceTicketListResponse.data.message;
                const serviceTicketList = serviceTicketListResponse.data.serviceTickets;

                const loadingButtonStatus = {};

                //creates an object with the key as the ticket number and the object contains if the start, complete buttons are locked based on its status
                for(const currentTicketInfo of serviceTicketList) {
        
                    loadingButtonStatus[currentTicketInfo.key] = {
                        start: false,
                        complete: false
                    };
        
                };
                
                setLoadingButtonStatus(loadingButtonStatus);
                setServiceTicketApiList(serviceTicketList);

                //updated the message depending on the api call message and list length
                renderServiceTicketApiCallMessage(serviceTicketMessage, serviceTicketList);

    
            } catch (error) {
                
                //sets the message to an error
                setServiceTicketMessageInfo({message: "An unexpected error has occurred", color: "red"});

            };

            setApiCallStatus("complete")

        };

        setSubmitButtonLock(false);

    };

    //updates the service ticket message depending on the api call message and list length
    const renderServiceTicketApiCallMessage = (apiMessage, apiList) => {

        if(apiMessage === "Invalid store URL") {

            setServiceTicketMessageInfo({message: "Invalid store URL", color: "red"});

        }else if(apiMessage === "No tickets found with provided info.") {

            setServiceTicketMessageInfo({message: "0 vendor tickets for the current store", color: "black"});

        }else {

            setServiceTicketMessageInfo({message: `${apiList.length} Ticket(s) Found`, color: "green"});

        }

    };

    //passed down to render the ticket confirmation popup
    const serviceTicketConfirmationPopup = (ticketInformation) => {

        setSelectedTicketInfo(ticketInformation)
        setTicketConfirmationPopupDisplay("flex");

    };

    //depending on user input, move to updated the database or hide the popup
    const serviceTicketConfirmationResponse = (confirmationStatus, updatedTicketInfo) => {

        //if false, hide the popup
        if(confirmationStatus === true) {

            serviceTicketStatusChange(updatedTicketInfo)
            
        }
        
        setTicketConfirmationPopupDisplay("none");

    };

    //api call to make update service ticket in database depending on button pressed
    const serviceTicketStatusChange = async (updatedTicketInfo) => {

        console.log(updatedTicketInfo)

        const submissionType = updatedTicketInfo.type;
        const serviceTicketKey = updatedTicketInfo.key;
        const serviceTicketNumber = updatedTicketInfo.ticketNumber;
        
        const vendorName = updatedTicketInfo["vendor name"] === undefined ? "" : updatedTicketInfo["vendor name"];
        const disposition = updatedTicketInfo["vendor disposition"] === undefined ? "" : updatedTicketInfo["vendor disposition"];

        const paramsList = Object.entries([...rawSearchParams]);

        const paramObject = {};

        /* iterates through the very weird response to the params and creates an object of the params (key:value) */
        for(const currentParam of paramsList) {

            const currentParamInfo = currentParam[1];

            paramObject[currentParamInfo[0]] = currentParamInfo[1]
        };

        //if store uid is not found, we cant continue
        if(paramObject.storeUid === undefined) {
                
            renderServiceTicketApiCallMessage("Invalid store URL");
            return

        };

        //does not need full url since its calling itself
        const updateServiceTicketEndpoint = `/api/serviceTicket/updatedSelectServiceTicket?storeUid=${paramObject.storeUid}`;
        
        const updateServiceTicketBody = {
            ticketNumber: serviceTicketNumber,
            key: serviceTicketKey,
            updateMethod: submissionType,
            vendorName: vendorName,
            vendorDisposition: disposition
        };

        const axiosUpdateServiceTicketSettings = {
            method: "post",
            maxBodyLength: Infinity,
            url: updateServiceTicketEndpoint,
            data: updateServiceTicketBody
        };

        //used to update the loading button status back to the original if an error occurred
        const originalLoadingButtonStatus = {...loadingButtonStatus};

        //grabs a deep copy of the loading button status
        let updatedLoadingButtonStatus = {...loadingButtonStatus};

        //updates the loading button status for the chosen ticket
        if(submissionType === "start") {

            updatedLoadingButtonStatus[serviceTicketKey].start = true;

        }else if(submissionType === "complete") {

            updatedLoadingButtonStatus[serviceTicketKey].complete = true;

        };

        setLoadingButtonStatus(updatedLoadingButtonStatus);

        try {

            //API call to update the database
            let updateServiceTicketResponse = await axios.request(axiosUpdateServiceTicketSettings);

            console.log(updateServiceTicketResponse)

            //await sleepFunction(2);

            //makes a new api call to grab the service ticket list to have the updated data in the app
            searchForServiceTicket();

            //updated the loading button status after the refresh api call
            updatedLoadingButtonStatus = {...loadingButtonStatus};
    
            if(submissionType === "start") {
    
                updatedLoadingButtonStatus[serviceTicketKey].start = false;
    
            }else if(submissionType === "complete") {
    
                updatedLoadingButtonStatus[serviceTicketKey].complete = false;
    
            };
    
            setLoadingButtonStatus(updatedLoadingButtonStatus);

        }catch (error) {

            setLoadingButtonStatus(originalLoadingButtonStatus)
            setServiceTicketMessageInfo({message: "An unexpected error has occurred", color: "red"});

        };

    };

    //on component load grabs store uid from url with no reload dependency
    useEffect(() => {

        //grab store uid from url
        const storeUid = grabStoreUid();
        
        //returns null if the storeUid value is not found
        setStoreUidState(storeUid);
        
        //confirms process is finished and loads
        setLoading(false)
    

    },[]);

    //if loading, show loading screen
    if(loading) {

        return <PageLoading />

    }

    return(

        <div
            //hides overflow if the popup is displayed to prevent scroll
            className="serviceTicketClockInContainer"
            style={{
                overflow: ticketConfirmationPopupDisplay === "block" ? "hidden" : "auto"
            }}

        >

            <TicketConfirmationPopup
                //popup component that displays to confirm selection of the service ticket
                extraInfoName={selectedTicketInfo.type === "start" ? "Vendor Name" : "Vendor Disposition"}
                display={ticketConfirmationPopupDisplay}
                ticketInformation={selectedTicketInfo}
                serviceTicketConfirmationResponse={serviceTicketConfirmationResponse}
            />

            <SurveyHeader
                //global header that displays the store icon and app name
                showImage={true}
                defaultImage={false}
                headerText={"Service Ticket's"}
                storeUid={storeUidState} //store uid to grab icon with
            />

            <div className="serviceTicketSearchInfoContainer">

                <div className="serviceTicketSearchInfoContainer_TicketCountContainer">
                
                    <p className="serviceTicketSearchMessage"
                        //displays the message depending on the api call message and list length
                        style={{color: serviceTicketMessageInfo.color, fontWeight: "bold"}}
                    >
                        {serviceTicketMessageInfo.message}
                    </p>
                
                </div>

                <div className="serviceTicketSearchInfoContainer_ButtonContainer">
                
                    <SubmitLoadingButton
                        //submit button that calls the api call to grab the service ticket list
                        onClick={searchForServiceTicket}
                        loadingStatus={apiCallStatus}
                        buttonLock={submitButtonLock}
                        buttonText="Search for tickets"
                    />
                
                </div>

            </div>

            <div className="serviceTicketInfoContainer">
                
                {serviceTicketApiList.map((currentTicket,index) => {
                    
                    //goes through the list of service tickets and renders them
                    const currentKey = `serviceTicketList-${index}`

                    return (

/*                         <div className="individualServiceTicketContainer" key={currentKey}>
 */
                            <RenderServiceTickets
                                currentTicketInfo={currentTicket}
                                key={currentKey}
                                serviceTicketConfirmationPopup={serviceTicketConfirmationPopup}
                                loadingButtonStatus={loadingButtonStatus[currentTicket.key]}
                            /> 
                            
/*                         </div>
 */                        
                    )

                })}

            </div>

        </div>
    )
};

export default ServiceTicketApp;
import React, {useState, useEffect} from "react";

//--- mui elements ---
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import "./ticketConfirmationPopup.css";

/**
 * Description: displays a popup to confirm selection of the service ticket
 * @param {*} props 
 * @returns 
 */
function TicketConfirmationPopup(props) {

    const [extraInfo, setExtraInfo] = useState("");
    const [vendorNameError, setVendorNameError] = useState(false);

    useEffect(() => {

        setExtraInfo("");
        setVendorNameError(false);

    },[props.ticketInformation])

    //if props are empty then return nothing otherwise the popup will try to grab data it cant and will break
    //TODO this seems like a hacky way of doing it
    if(props.ticketInformation.ticketDescription === undefined) {

        return <div></div>

    };

    const changeVendorName = (event) => {

        const updatedField = event.target.value;

        setExtraInfo(updatedField)

    };

    const confirmSubmission = (ticketConfirmationResponse) => {

        if(ticketConfirmationResponse === false) {

            props.serviceTicketConfirmationResponse(false)
            return
        
        };

        if(extraInfo.length === 0) {

            setVendorNameError(true)
            return

        };

        setVendorNameError(false);

        const updatedTicketInfo = {...props.ticketInformation};

        const extraInfoKey = props.extraInfoName.toLowerCase();

        updatedTicketInfo[extraInfoKey] = extraInfo;

        props.serviceTicketConfirmationResponse(true, updatedTicketInfo);


    };

    return (
        
        <div 
            className="ticketConfirmationPopupContainer"
            style={{
                display: props.display,
            }}
        >

            <div className="ticketConfirmationPopupChoiceContainer">

                <div className="ticketConfirmationPopupDisplayData">

                    <h1>Ticket Confirmation</h1>            

                    <p><b>Number:</b> {props.ticketInformation.ticketNumber}</p>

                    <TextField 
                        style={{
                            width: "95%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        value={extraInfo}
                        variant="outlined"
                        size="small"
                        label={props.extraInfoName}
                        required={true}
                        error={vendorNameError}
                        onChange={changeVendorName}
                    />

                    <img src={props.ticketInformation.pictures.imageLinksList[0]} alt="image of broken equipment" />

                </div>

                <div className="ticketConfirmationPopupDisplayDataButtonsContainer">

                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => confirmSubmission(true)}
                    >
                            Confirm
                    </Button>

                    <Button
                        variant="contained"
                        color="error"                    
                        onClick={() => confirmSubmission(false)}
                    >
                        Cancel
                    </Button>
                
                </div>

            </div>

        </div>
    );

};

export default TicketConfirmationPopup;
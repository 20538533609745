import React, {useEffect, useState} from "react";
import axios from "axios";

import "./surveyHeader.css"

import CircularProgress from '@mui/material/CircularProgress';

const noClientFound = require(`../images/logos/noLogoFound2.png`);
const mcdLogo = require(`../images/logos/McDLogo.png`);

//generates the survey header for the app
export default function SurveyHeader(props) {

    const [currentImage, setCurrentImage] = useState({
        imageFile: noClientFound,
        imageName: "noClientImageFound"
    });

    const [loadingImage, setLoadingImage] = useState(true);

    useEffect(() => {

        grabImageName();

        //optional return function
    },[]); //The dependency array this will always run atleast 1 time because of component did mount

    //grabs image info for the current client
    const grabImageName = async () => {
    
        let storeUid = props.storeUid;

        try{

            //does not need full url since its calling itself
            const staticLogoNameApiUrl = `/api/grabImages/staticLogoType?storeUid=${storeUid}`;

            const getStaticLogoNameConfig = {
                method: "get",
                maxBodyLength: Infinity,
                url: staticLogoNameApiUrl,
            };

            let response = await axios.request(getStaticLogoNameConfig);

            const responseData = response.data;

            //update image if the image identifier is found
            if(responseData !== undefined && responseData.staticImageIdentifier !== undefined) {

                const storeIdentifier = responseData.staticImageIdentifier;

                const clientImage = require(`../images/logos/${storeIdentifier}.png`)

                setCurrentImage({
                    imageFile: clientImage,
                    imageName: storeIdentifier
                });

                setLoadingImage(false);                    

            }else {

                setLoadingImage(false);

            }
            
        }catch(error) {

            //since an error was encountered, we dont update the image state and turn off the loading state
            setLoadingImage(false);

        }

    };

    //renders the image differently depending on props
    const generateSelectedImage = () => {

        if(props.showImage === false) {

            return ""

        }else if(props.defaultImage === true) {

            return (

                <img src={mcdLogo} alt="companyLogo" className="groupLogo" />
            )

        }else if(loadingImage === true) {

            return (
                <CircularProgress />
            )

        }else {

            return (
                <img src={currentImage.imageFile} alt="companyLogo" className="groupLogo" />
            )
            
        }

    };

    //can pass a promise from a server as a prop and defile the props as a promise
    //can call use() so it waits for a response from the parent value
    return(

        <div className="surveyHeaderContainer">

            <div className="surveyHeaderLogoContainer">

                {generateSelectedImage()}

            </div>

            <div className="surveyHeaderTextContainer">

                <p className="surveyHeader">{props.headerText}</p>
            
            </div>

        </div>

    )

};